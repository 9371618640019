const _lsFallback = {}

export const lsSet = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    _lsFallback[key] = value
  }
}
export const lsGet = (key) => {
  try {
    return JSON.parse(window.localStorage.getItem(key))
  } catch (e) {
    return _lsFallback[key]
  }
}
