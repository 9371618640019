import { useEffect, useState } from "preact/hooks"
import { Picture } from "./picture"
import Thumb from "../assets/thumb-down.gif"

const randomNumber = () => Math.round(Math.random())
const ResultType = {
  Correct: "Correct",
  Incorrect: "Incorrect",
}

export const Game = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState(null)
  const [isReversedOrder, setIsReversedOrder] = useState(randomNumber)
  const [showResult, setShowResult] = useState(false)

  const _onSubmit = (e) => {
    e.preventDefault()
    if (!showResult) {
      const submitter = e.submitter
      const i = Number.parseInt(submitter.getAttribute("data-index") || "", 10)
      const isReal = Boolean(i - isReversedOrder)
      setShowResult(isReal ? ResultType.Correct : ResultType.Incorrect)
    }
  }

  const _onContinue = (e) => {
    e.preventDefault()
    fetchData()
  }

  const _onAgain = (e) => {
    e.preventDefault()
    setShowResult(false)
  }

  const fetchData = () => {
    setIsLoading(true)
    setShowResult(false)
    fetch("https://bits.feinheit.ch/api/getGame")
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "SUCCESS") {
          setIsReversedOrder(randomNumber())
          setData(data)
        }
        setIsLoading(false)
      })
      .catch((err) => {
        setData(null)
        console.error(err)
      })
  }

  useEffect(() => {
    fetchData()
    return () => {
      setData(null)
    }
  }, [])

  if (!data)
    return (
      <div className="fake-or-not__filler">
        <div className="fake-or-not__filler-child">
          {isLoading ? (
            <div className="spinner-border" role="status" />
          ) : (
            <div className="spinner-grow" role="status" />
          )}
        </div>
        <div className="fake-or-not__filler-child">
          {isLoading ? (
            <div className="spinner-border" role="status" />
          ) : (
            <div className="spinner-grow" role="status" />
          )}
        </div>
      </div>
    )

  return data ? (
    <>
      <form onSubmit={_onSubmit} className="fake-or-not__game">
        {!isReversedOrder
          ? data.files.map((img, index) => (
              <Picture
                url={img}
                key={img}
                fetchData={fetchData}
                index={index}
                showResult={showResult}
              />
            ))
          : [...data.files]
              .reverse()
              .map((img, index) => (
                <Picture
                  url={img}
                  key={img}
                  fetchData={fetchData}
                  index={index}
                  showResult={showResult}
                />
              ))}
      </form>
      <div className="fake-or-not__content">
        <h2>
          {data.detail.firstname} {data.detail.lastname}
        </h2>
        <span>{data.detail.council}</span> | <span>{data.detail.party}</span> |{" "}
        <span>{data.detail.canton}</span>
      </div>

      {showResult ? (
        <div className="fake-or-not__overlay">
          <>
            {showResult === ResultType.Correct ? (
              <>
                <img
                  alt="Thumb Up"
                  src={Thumb}
                  className="fake-or-not__icon fake-or-not__icon--up"
                />
                <h2>Richtig</h2>
              </>
            ) : null}

            {showResult === ResultType.Incorrect ? (
              <>
                <img
                  alt="Thumb Down"
                  src={Thumb}
                  className="fake-or-not__icon fake-or-not__icon--down"
                />
                <h2>Falsch</h2>
              </>
            ) : null}
          </>
          <div className="fake-or-not__button-group">
            <button className="button fake-or-not__button" onClick={_onAgain}>
              Nochmal
            </button>
            <button
              className="button fake-or-not__button"
              onClick={_onContinue}
            >
              Weiter
            </button>
          </div>
        </div>
      ) : null}
    </>
  ) : null
}
