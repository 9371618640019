import { initMasonry } from "./masonry.js"
import { qsa } from "./utils.js"

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    const { isIntersecting, target } = entry
    if (isIntersecting) {
      observer.unobserve(target)

      fetch(target.dataset.infinite)
        .then((response) => response.text())
        .then((html) => {
          target.outerHTML = html
          initMasonry()
          initInfinite()
        })
    }
  })
})

export function initInfinite() {
  for (const el of qsa("[data-infinite]")) {
    observer.observe(el)
  }
}
